
import Vue from "vue";
interface ArticleProps {
  totalHits: number;
}

export default Vue.extend<{}, {}, {}, ArticleProps>({
  name: "HitsAmount",
  props: {
    totalHits: Number,
  },
});
