import { MutationTree, Mutation } from "vuex";
import { IArticle, IState, ISearchHit } from "./state";
import { ActionPayload } from "./actions";

type MutationHandler<PayloadType> = (
  state: IState,
  payload: ActionPayload<PayloadType>
) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
  Object.assign(state, payload);
};
const setCurrentPageMutation: MutationHandler<number> = (
  state,
  { payload }
) => {
  state.currentPage = payload;
};
const setArticlesMutation: MutationHandler<IArticle[]> = (
  state,
  { payload }
) => {
  state.articles = payload;
};
const shouldFocusMutation: MutationHandler<boolean> = (state, { payload }) => {
  state.shouldFocus = payload;
};
const setSelectedMonthMutation: MutationHandler<number> = (
  state,
  { payload }
) => {
  state.months.forEach((m) => {
    if (m.value == payload) {
      m.selected = true;
    } else {
      m.selected = false;
    }
  });
};
const setSelectedYearMutation: MutationHandler<number> = (
  state,
  { payload }
) => {
  console.log(payload);
  state.years.forEach((y) => {
    if (y.value == payload) {
      y.selected = true;
    } else {
      y.selected = false;
    }
  });
};
const setTotalHitsMutation: MutationHandler<number> = (state, { payload }) => {
  state.totalHits = payload;
};
const setActivePageMutation: MutationHandler<number> = (state, { payload }) => {
  state.currentPage = payload;
};
const decreasePageMutation: MutationHandler<IState> = (state, { payload }) => {
  Object.assign(state, { currentPage: state.currentPage - 1 });
};
const increasePageMutation: MutationHandler<IState> = (state, { payload }) => {
  Object.assign(state, { currentPage: state.currentPage + 1 });
};
const setHitsMutation: MutationHandler<ISearchHit[]> = (state, { payload }) => {
  console.log("Setting hits: ", payload);
  state.searchResult.searchHits = payload;
};

const mutations: MutationTree<IState> = {
  setState: setStateMutation,
  setCurrentPage: setCurrentPageMutation,
  setArticles: setArticlesMutation,
  shouldFocus: shouldFocusMutation,
  setSelectedYear: setSelectedYearMutation,
  setSelectedMonth: setSelectedMonthMutation,
  setTotalHits: setTotalHitsMutation,
  setActivePage: setActivePageMutation,
  decreasePage: decreasePageMutation,
  increasePage: increasePageMutation,
  setHits: setHitsMutation,
};

export default mutations;
