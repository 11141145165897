
import Vue from "vue";
import { filter } from "../store/actions";
import { IMonth, IYear } from "../store/state";

interface FiltersComputed {
  years: IYear[];
  months: IMonth[];
}
interface FiltersMethods {
  yearChanged(event: Event): void;
  monthChanged(event: Event): void;
  filter(): void;
}

export default Vue.extend<{}, FiltersMethods, FiltersComputed, {}>({
  name: "Filter",
  computed: {
    years() {
      return this.$store.getters.years;
    },
    months() {
      return this.$store.getters.months;
    },
  },
  methods: {
    yearChanged(event) {
      const value = (event.target as HTMLSelectElement).value;
      console.log(value);
      this.$store.commit({
        type: "setSelectedYear",
        payload: value,
      });
    },
    monthChanged(event) {
      const value = (event.target as HTMLSelectElement).value;
      this.$store.commit({
        type: "setSelectedMonth",
        payload: value,
      });
    },
    filter() {
      this.$store.dispatch(filter(undefined));
    },
  },
});
