export interface IState {
  articles: IArticle[];
  searchResult: ISearchResult;
  totalHits: number;
  currentPage: number;
  hitsPerPage: number;
  searchURL: string;
  shouldFocus: boolean;
  months: IMonth[];
  years: IYear[];
  query: string;
}
export interface IMonth {
  name: string;
  value?: number;
  selected: boolean;
}
export interface IYear {
  name: string;
  value?: number;
  selected: boolean;
}
export interface ISearchResult {
  searchHits: ISearchHit[];
  totalHits: number;
  suggestion?: string;
}
export interface ISearchHit {
  type: string;
  category: string;
  title: string;
  breadcrumbs: string;
}
export interface IArticle {
  title: string;
  preamble: string;
  uri: string;
  location: string;
  date: string;
}
const deafultState: IState = {
  searchResult: {
    searchHits: [],
    totalHits: 0,
  },
  articles: [],
  totalHits: 0,
  currentPage: 0,
  hitsPerPage: 0,
  searchURL: "",
  shouldFocus: false,
  months: [],
  years: [],
  query: "",
};

export default deafultState;
