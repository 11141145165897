
import Vue from "vue";
import { IArticle } from "../store/state";
interface ArticleProps {
  article: IArticle;
}

export default Vue.extend<{}, {}, {}, ArticleProps>({
  name: "Article",
  props: {
    article: {},
  },
});
