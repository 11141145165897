
import Vue from "vue";
import Article from "./Article.vue";
interface ArticlesProps {
  message: string;
}

export default Vue.extend<{}, {}, {}, ArticlesProps>({
  name: "Articles",
  computed: {
    articles() {
      return this.$store.getters.articles;
    },
    hits() {
      console.log(
        "this.$store.getters.hits: " + JSON.stringify(this.$store.getters.hits)
      );
      return this.$store.getters.hits;
    },
  },
  updated() {
    if (this.$store.getters.shouldFocus) {
      const articles = this.$refs.articles as Array<any>;
      const first = articles[0].$el as HTMLElement;

      const y = first.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({
        top: y,
        behavior: "smooth",
      });

      this.$store.commit({
        type: "shouldFocus",
        payload: false,
      });
    }
  },
  components: {
    Article,
  },
});
