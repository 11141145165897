import { IState } from "./state";
import { ActionTree, Store, ActionContext } from "vuex";
import * as api from "../api";

type PayloadType =
  | "initApp"
  | "setState"
  | "setQuery"
  | "makeSearch"
  | "toggleFilter"
  | "deselectAllFilters"
  | "searchSuggestion"
  | "setActivePage"
  | "filter";
export type ActionPayload<ActionData> = {
  type: PayloadType;
  payload: ActionData;
};
export interface IToggleFilterPayload {
  id: string;
  value: boolean;
}

type ActionHandler<ActionData> = (
  this: Store<IState>,
  injectee: ActionContext<IState, IState>,
  payload: ActionPayload<ActionData>
) => any;
type ActionCreator<ActionData> = (
  data: ActionData
) => ActionPayload<ActionData>;

// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
  type: "initApp",
  payload: state,
});
export const makeSearch: ActionCreator<undefined> = (undefined) => ({
  type: "makeSearch",
  payload: undefined,
});
export const setQuery: ActionCreator<string> = (query) => ({
  type: "setQuery",
  payload: query,
});
export const searchSuggestion: ActionCreator<string> = (payload) => ({
  type: "searchSuggestion",
  payload: payload,
});
export const setActivePage: ActionCreator<number> = (value) => ({
  type: "setActivePage",
  payload: value,
});
export const filter: ActionCreator<undefined> = (payload) => ({
  type: "filter",
  payload: payload,
});

// Action handlers
const filterAction: ActionHandler<undefined> = (
  { commit, state },
  { payload }
) => {
  commit({
    type: "setCurrentPage",
    payload: 0,
  });
  console.log(state.years);
  api
    .search(state.searchURL, {
      page: state.currentPage,
      yearFilter: state.years.find((y) => y.selected),
      monthFilter: state.months.find((m) => m.selected),
    })
    .then((res) => {
      commit({
        type: "setTotalHits",
        payload: res.totalHits,
      });
      commit({
        type: "setHits",
        payload: res.searchHits,
      });
    });
};
const searchSuggestionAction: ActionHandler<undefined> = (
  { commit, state, dispatch },
  { payload }
) => {
  commit({
    type: "setQuery",
    payload: payload,
  });
  dispatch({
    type: "makeSearch",
    payload: undefined,
  });
};
const setQueryAction: ActionHandler<string> = ({ commit }, { payload }) => {
  commit({
    type: "setQuery",
    payload,
  });
};
const makeSearchAction: ActionHandler<undefined> = (
  { commit, state },
  { payload }
) => {
  api
    .search(state.searchURL, {
      page: 0,
      query: state.query,
    })
    .then((res) => {
      commit({
        type: "newSearch",
        payload: res,
      });
      commit({
        type: "setSearchedQuery",
        payload: undefined,
      });
      commit({
        type: "setSuggestion",
        payload: res.suggestion,
      });
    });
};
const setActivePageAction: ActionHandler<number> = (
  { commit, state },
  { payload }
) => {
  api
    .search(state.searchURL, {
      page: payload,
      query: state.query,
    })
    .then((res) => {
      commit({
        type: "setActivePage",
        payload: payload,
      });
      commit({
        type: "setHits",
        payload: res.searchHits,
      });
    });
};
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
  commit({
    type: "setState",
    payload,
  });
};
const actions: ActionTree<IState, IState> = {
  initApp: initAppAction,
  makeSearch: makeSearchAction,
  setQuery: setQueryAction,
  searchSuggestion: searchSuggestionAction,
  setActivePage: setActivePageAction,
  filter: filterAction,
};

export default actions;
