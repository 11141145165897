import { Getter } from "vuex";
import { IState } from "./state";

const articles: Getter<IState, IState> = (state) => state.articles;
const hits: Getter<IState, IState> = (state) => state.searchResult.searchHits;
const pageCount: Getter<IState, IState> = (state) =>
  Math.ceil(state.totalHits / state.hitsPerPage);
const totalHits: Getter<IState, IState> = (state) => state.totalHits;
const hitsPerPage: Getter<IState, IState> = (state) => state.hitsPerPage;
const currentPage: Getter<IState, IState> = (state) => state.currentPage;
const shouldFocus: Getter<IState, IState> = (state) => state.shouldFocus;
const months: Getter<IState, IState> = (state) => state.months;
const years: Getter<IState, IState> = (state) => state.years;
const searchURL: Getter<IState, IState> = (state) => state.searchURL;
const selectedYear: Getter<IState, IState> = (state) =>
  state.years.find((y) => y.selected);

export default {
  articles,
  hits,
  pageCount,
  totalHits,
  hitsPerPage,
  currentPage,
  shouldFocus,
  months,
  years,
  searchURL,
  selectedYear,
};
