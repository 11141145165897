var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.numberOfPages > 1)?_c('nav',{staticClass:"hiq-calendar-archive__pagination",attrs:{"aria-label":"paginering nyhetslistning"}},[_c('div',{staticClass:"hiq-calendar-archive__pagination-prev"},[_c('button',{staticClass:"hiq-calendar-archive__pagination-button",attrs:{"disabled":_vm.getActivePage === 0,"aria-disabled":_vm.getActivePage === 0},on:{"click":function($event){return _vm.setActivePage(_vm.getActivePage - 1)}}},[_c('span',{staticClass:"hiq-calendar-archive__pagination-prev-label hiq-calendar-archive__pagination-nav-label"},[_vm._v("Föregående")])])]),_c('ul',{staticClass:"hiq-calendar-archive__pagination-list"},_vm._l((_vm.visiblePageButtons),function(page){return _c('li',{key:page,staticClass:"hiq-calendar-archive__pagination-list-item"},[_c('button',{class:[
          page - 1 === _vm.getActivePage
            ? 'hiq-calendar-archive__pagination-button hiq-calendar-archive__pagination-list-item-button hiq-calendar-archive__pagination-button--onlyread'
            : '',
          _vm.getActivePage === page - 1
            ? 'hiq-calendar-archive__pagination-button hiq-calendar-archive__pagination-list-item-button hiq-calendar-archive__pagination-list-item-button--active'
            : '',
          _vm.getActivePage === page - 1
            ? 'hiq-calendar-archive__pagination-button hiq-calendar-archive__pagination-list-item-button hiq-calendar-archive__pagination-list-item-button--active'
            : '',
          page === '...'
            ? 'hiq-calendar-archive__pagination-rest-button'
            : 'hiq-calendar-archive__pagination-button hiq-calendar-archive__pagination-list-item-button',
        ],attrs:{"disabled":page === '...',"aria-current":[page - 1 === _vm.getActivePage ? 'page' : ''],"aria-label":'Sida ' + page},on:{"click":function($event){if (page - 1 !== _vm.getActivePage) _vm.setActivePage(page - 1);}}},[(page != '...')?_c('span',[_vm._v(_vm._s(page))]):_vm._e()])])}),0),_c('div',{staticClass:"hiq-calendar-archive__pagination-next"},[_c('button',{staticClass:"hiq-calendar-archive__pagination-button",attrs:{"disabled":_vm.getActivePage == _vm.numberOfPages - 1,"aria-disabled":_vm.getActivePage == _vm.numberOfPages - 1},on:{"click":function($event){return _vm.setActivePage(_vm.getActivePage + 1)}}},[_c('span',{staticClass:"hiq-calendar-archive__pagination-next-label hiq-calendar-archive__pagination-nav-label"},[_vm._v("Nästa")])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }