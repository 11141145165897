
import Vue from "vue";
import Articles from "./components/Articles.vue";
import HitsAmount from "./components/HitsAmount.vue";
import Pagination from "./components/Pagination.vue";
import Filters from "./components/Filters.vue";
// Interfaces for component typechecking!
interface AppData {}

interface AppMethods {}

interface AppComputed {
  pageCount: number;
  articlesPerPage: number;
  totalHits: number;
  resultsCount: number;
  resultsPerPage: number;
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
  computed: {
    pageCount() {
      return this.$store.getters.pageCount;
    },
    articlesPerPage() {
      return this.$store.getters.articlesPerPage;
    },
    totalHits() {
      return this.$store.getters.totalHits;
    },
    resultsCount() {
      return this.$store.getters.totalHits;
    },
    resultsPerPage() {
      return this.$store.getters.hitsPerPage;
    },
  },
  components: {
    Articles,
    HitsAmount,
    Pagination,
    Filters,
  },
});
