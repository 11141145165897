
import Vue from "vue";
import state from "../store/state";
import { setActivePage } from "../store/actions";

interface PaginationData {}

interface PaginationMethods {
  setActivePage(page: number): void;
}

interface PaginationComputed {
  numberOfPages: any;
  getActivePage: any;
  visiblePageButtons: any;
}

interface PaginationProps {
  resultsCount: number;
  resultsPerPage: number;
  scrollToAtChange: string;
}

interface PaginationCreated {
  resultsCount: number;
  resultsPerPage: number;
  scrollToAtChange: string;
}

export default Vue.extend<
  PaginationData,
  PaginationMethods,
  PaginationComputed,
  PaginationProps
>({
  name: "Pagination",
  computed: {
    numberOfPages() {
      let perPage: number = this.$store.getters.hitsPerPage;
      /*
                Too see how the pagination behaves with a large amount of pages,
                do a manual return of eg. 36
            */
      console.log("totalhits: ", this.$store.getters.totalHits);
      console.log("per: ", this.$store.getters.hitsPerPage);
      return Math.max(1, Math.ceil(this.$store.getters.totalHits / perPage));
    },
    visiblePageButtons() {
      let output = [];
      let buttonsAmount = 7;
      let midPart = [];
      let etc = "...";

      // First page
      output.push(1);

      // Mid part
      // If not that many pages
      if (this.numberOfPages <= buttonsAmount) {
        let i;
        for (i = 2; i < this.numberOfPages; i++) {
          midPart.push(i);
        }
      }
      // Mid part
      // If many pages
      else {
        // If active is near the end
        if (this.getActivePage > this.numberOfPages - 3) {
          midPart.push(etc);
          let i;
          for (i = this.numberOfPages - 3; i < this.numberOfPages; i++) {
            midPart.push(i);
          }
        }
        // If active is near the start
        else if (this.getActivePage <= 3) {
          let i;
          for (i = 2; i < 5; i++) {
            midPart.push(i);
          }
          midPart.push(etc);
        }
        // If active is nowhere near the start or end page
        else {
          midPart.push(etc);
          let i;
          for (i = this.getActivePage - 1; i < this.getActivePage + 2; i++) {
            midPart.push(i);
          }
          midPart.push(etc);
        }
      }

      // What ever mid became, we add it here
      output = [...output, ...midPart];

      // Last page
      if (buttonsAmount > 1) output.push(this.numberOfPages);
      return output;
    },
    getActivePage() {
      return this.$store.getters.currentPage;
    },
  },
  methods: {
    setActivePage(page) {
      if (this.scrollToAtChange.length > 0) {
        let ourHeading = document.querySelector(this.scrollToAtChange);
        if (ourHeading != null && ourHeading instanceof HTMLElement) {
          ourHeading.scrollIntoView({ behavior: "smooth" });
        }
      }
      setTimeout(() => this.$store.dispatch(setActivePage(page)), 500);
    },
  },
  props: {
    resultsCount: Number,
    resultsPerPage: Number,
    scrollToAtChange: String,
  },
});
